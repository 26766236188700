import React, { useState } from "react";
// Components
import PopupMessage from "components/PopupMessage/PopupMessage";
import { Button } from "components/Wrappers/Wrappers";
// Functions and helpers
import { dbPost } from "utils/DBFetchers.js";


export default function ResendConfirmationDialog({
  open,
  onClose,
  email,
  automaticEmailId,
  snackbarOpen,
  refreshData,
}) {
  const [loading, setLoading] = useState(false);

  const resendAutomaticEmail = async (automaticEmailId) => {
    setLoading(true);
    try {
      await dbPost(`burnable/resend/${automaticEmailId}`);
      snackbarOpen("success", "Correo reenviado exitosamente.");
      await refreshData();
      onClose();
    } catch (error) {
      snackbarOpen(
        "error",
        error[0] ? error[0].description_es : "Error al reenviar correo.",
      );
    }
    setLoading(false);
  };

  return (
    <PopupMessage
      open={open}
      handleClose={onClose}
      title="¿Estás seguro de que deseas reenviar el correo?"
      message={`Al reenviar el correo, se enviará a ${email}`}
      closeColor="secondary"
      executeButton={
        <Button
          variant="contained"
          color="primary"
          onClick={() => resendAutomaticEmail(automaticEmailId)}
          disabled={loading}
        >
          Reenviar Email
        </Button>
      }
    />
  );
};