import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Autocomplete } from "@material-ui/lab";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import DateField from "components/CustomFields/DateField";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Stack } from "@mui/material";
import { dbGet } from "utils/DBFetchers";
import { getCurrentDate } from "utils/dateGetters.js";
// styles
import useStyles from "../SelectionForm/styles";

const FILTERS = {
  fromDate: getCurrentDate(),
  toDate: getCurrentDate(),
  brand: null,
  bricks: null,
  presentation: null,
  businessArea: null,
  therapeuticAction: null,
};

const SELECT_INFORMATION = {
  bricks: [],
  brands: [],
  presentations: [],
  businessAreas: [],
  therapeuticAction: [],
};

export default function GeneralDashboardFilters({
  children = null,
  initialFiltersEndpoint,
  getEndpoint,
  setIsLoading,
  isLoading,
  generateRowsData,
  programId,
}) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [filters, setFilters] = useState(FILTERS);
  const [maxPeriod, setMaxPeriod] = useState(getCurrentDate());
  const [period, setPeriod] = useState(getCurrentDate());
  const [selectInformation, setSelectInformation] =
    useState(SELECT_INFORMATION);

  useEffect(() => {
    getInitialInformation();
    getLastPeriod();
  }, []);

  const getLastPeriod = () => {
    let url = `control-panel/get_last_period?program_id=${programId}`;
    dbGet(url).then((response) => {
      if (response == null) {
        setMaxPeriod(getCurrentDate());
        setPeriod(getCurrentDate());
        return;
      }
      let year = parseInt(response.toString().substring(0, 4));
      let month = parseInt(response.toString().substring(4, 6));
      setMaxPeriod(new Date(year, month - 1, 1));
      setPeriod(new Date(year, month - 1, 1));
      setFilters({
        ...filters,
        fromDate: new Date(year, 1, 0),
        toDate: new Date(year, month - 1, 1),
      });
    });
  };

  const validDateFilters = () => {
    let validDate =
      filters.fromDate && filters.toDate && filters.toDate >= filters.fromDate;
    return validDate;
  };

  const handleFilterChange = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const getPeriodFromDate = (date) => {
    return parseInt(
      date.toISOString().split("T")[0].substring(0, 7).replace("-", ""),
    );
  };

  const getInitialInformation = () => {
    dbGet(initialFiltersEndpoint)
      .then((data) => {
        setSelectInformation({
          bricks: data.bricks,
          brands: data.brands,
          presentations: data.presentations,
          businessAreas: data.business_areas,
          therapeuticAction: data.therapeutical_actions,
        });
      })
      .catch((error) => {});
  };

  const handleApplyFilters = () => {
    if (!validDateFilters()) return;
    let fromPeriod = `?from_period=${getPeriodFromDate(filters.fromDate)}`;
    let toPeriod = `&to_period=${getPeriodFromDate(filters.toDate)}`;
    let program = programId ? `&program_id=${programId}` : "";
    let brick = filters.brick ? `&brick_ids=${filters.brick}` : "";
    let brand = filters.brand ? `&presentation_ids=${filters.brand}` : "";
    let presentation = filters.presentation
      ? `&presentation_ids=${filters.presentation}`
      : "";
    let businessArea = filters.businessArea
      ? `&presentation_ids=${filters.businessArea}`
      : "";
    let therapeuticAction = filters.therapeuticAction
      ? `&presentation_ids=${filters.therapeuticAction}`
      : "";
    let url = `${getEndpoint}${fromPeriod}${toPeriod}${program}${brick}${brand}${presentation}${businessArea}${therapeuticAction}`;
    setIsLoading(true);
    dbGet(url)
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id), 3000);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos");
        setIsLoading(false);
      });
  };

  const updateProgress = (id) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((response) => {
        if (response.status === "Completed") {
          setIsLoading(false);
          if (Object.keys(response.data).length === 0) {
            snackbarOpen(
              "warning",
              "No hay información generada sobre ninguno de estos períodos",
            );
          }
          generateRowsData(response.data);
        } else if (!response.error_status && response.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, response), 2000);
        } else {
          snackbarOpen("error", "Error al obtener los datos de la consulta");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos de la consulta");
        setIsLoading(false);
      });
  };

  const handleBrickChange = (event, newValue) => {
    handleFilterChange("brick", newValue ? newValue.id : newValue);
  };

  const handlePresentationChange = (event, newValue) => {
    handleFilterChange("presentation", newValue ? newValue.id : newValue);
  };

  const handleBusinessAreaChange = (event, newValue) => {
    handleFilterChange("businessArea", newValue ? newValue.id : newValue);
  };

  const handleBrandChange = (event, newValue) => {
    handleFilterChange("brand", newValue ? newValue.id : newValue);
  };

  const handleTherapeuticActionChange = (event, newValue) => {
    handleFilterChange("therapeuticAction", newValue ? newValue.id : newValue);
  };

  const handleToPeriodChange = (date) => {
    let toDateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1); 
    let fromDateOnFirstDay = new Date(filters.fromDate.getFullYear(), filters.fromDate.getMonth(), 1);
    
    if (toDateOnFirstDay < fromDateOnFirstDay) {
      snackbarOpen("error", "El período final no puede ser menor al inicial");
      return;
    }
    
    setFilters({
      ...filters,
      toDate: toDateOnFirstDay,
    });
  };

  const handleFromPeriodChange = (date) => {
    let fromDateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let toDateOnFirstDay = new Date(filters.toDate.getFullYear(), filters.toDate.getMonth(), 1);

    if (fromDateOnFirstDay > toDateOnFirstDay) {
      snackbarOpen("error", "El período final no puede ser menor al inicial");
      return;
    }

    setFilters({
      ...filters,
      fromDate: fromDateOnFirstDay,
    });
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        item
        xs={12}
        lg={12}
        style={{ marginBottom: "10px", marginLeft: "2.5%" }}
      >
        <Typography variant="h4" className={classes.alignedText}>
          Selección
        </Typography>
        <Divider />
      </Grid>

      <Grid container justifyContent="center">
        <Grid item container xs={12} justifyContent="center">
          <Grid item container xs={12} md={5}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Período inicial
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <DateField
                disabled={isLoading}
                views={["year", "month"]}
                minDate={new Date("2020-01-02")}
                maxDate={maxPeriod}
                format={"MM/yyyy"}
                value={filters.fromDate}
                onChange={handleFromPeriodChange}
                fieldClassName={classes.dateSelectionField}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} md={5}>
            <Grid item xs={5} md={3} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Período final
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} className={classes.alignLeft}>
              <DateField
                disabled={isLoading}
                views={["year", "month"]}
                minDate={new Date("2020-01-02")}
                maxDate={maxPeriod}
                format={"MM/yyyy"}
                value={filters.toDate}
                onChange={handleToPeriodChange}
                fieldClassName={classes.dateSelectionField}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          style={{ marginTop: "5px" }}
        >
          <Grid item container xs={12} md={5}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Bricks
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Autocomplete
                disabled={isLoading}
                className={classes.simpleSelectionField}
                id="bricks"
                autoHighlight
                onChange={handleBrickChange}
                options={selectInformation.bricks || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione" />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} md={5}>
            <Grid item xs={5} md={3} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Acción terapeutica
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} className={classes.alignLeft}>
              <Autocomplete
                disabled={
                  isLoading ||
                  filters.businessArea ||
                  filters.presentation ||
                  filters.brand
                }
                className={classes.simpleSecondColumnSelectionField}
                id="therapeuticAction"
                autoHighlight
                onChange={handleTherapeuticActionChange}
                options={selectInformation.therapeuticAction || []}
                getOptionLabel={(option) => option.name}
                sx={{ width: 700 }}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          style={{ marginTop: "5px" }}
        >
          <Grid item container xs={12} md={5}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Marca
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Autocomplete
                disabled={
                  isLoading ||
                  filters.businessArea ||
                  filters.presentation ||
                  filters.therapeuticAction
                }
                className={classes.simpleSelectionField}
                autoHighlight
                id="brand"
                options={selectInformation.brands || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Tipee para buscar" />
                )}
                onChange={handleBrandChange}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={5} alignItems="center">
            <Grid item xs={5} md={3} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Presentación
              </Typography>
            </Grid>
            <Grid item xs={7} md={9} className={classes.alignLeft}>
              <Autocomplete
                disabled={
                  isLoading ||
                  filters.therapeuticAction ||
                  filters.businessArea ||
                  filters.brand
                }
                className={classes.simpleSecondColumnSelectionField}
                autoHighlight
                id="presentation"
                options={selectInformation.presentations || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Tipee para buscar" />
                )}
                onChange={handlePresentationChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "5px" }}
        >
          <Grid item container xs={12} md={5} alignItems="center">
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Área de negocio
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Autocomplete
                disabled={
                  isLoading ||
                  filters.therapeuticAction ||
                  filters.presentation ||
                  filters.brand
                }
                className={classes.simpleSelectionField}
                autoHighlight
                id="businessArea"
                options={selectInformation.businessAreas || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione" />
                )}
                onChange={handleBusinessAreaChange}
              />
            </Grid>
          </Grid>
          <Grid item container xs={5}></Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: "20px", marginBottom: "10px" }}
        spacing={2}
        className={classes.uploadButton}
      >
        <Stack direction="row" spacing={2}>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
          >
            Aplicar
          </Button>
          {children}
        </Stack>
      </Grid>
    </>
  );
}
