import React, { useState, useEffect } from "react";
// Components
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import PageTitle from "components/PageTitle/PageTitle";
import { Typography } from "components/Wrappers/Wrappers";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { Grid, CircularProgress, Divider } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import SimpleRutInput from "./SimpleRutInput";
import EditablePatientInfo from "./EditablePatientInfo";
import UnsubscribePatientDialog from "components/burnable/ConsultUserDataPage/UnsubscribePatientDialog";
import SubscriptionDetailsDialog from "components/burnable/ConsultUserDataPage/SubscriptionDetailsDialog";
import ResendConfirmationDialog from "components/burnable/ConsultUserDataPage/ResendConfirmationDialog";
// Functions and helpers
import { verifyRut } from "utils/rutValidations.js";
import { dbGet, dbPut } from "utils/DBFetchers.js";
import {
  createSubscriptionsColumns,
  salesWithoutCouponColumns,
  mapSubscriptions,
  mapSalesWithoutCoupon,
} from "./table-helpers";

// styles
import useStyles from "../styles";

const blankPatientData = {
  id: "",
  rut: "",
  email: "",
  phone: "",
  name: "",
  lastname: "",
};

export default function ConsultPatientPage(props) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [salesWithoutCoupon, setSalesWithoutCoupon] = useState([]);
  const [isRutValid, setIsRutValid] = useState({ patient: false, message: "" });
  const [searchRut, setSearchRut] = useState("");
  const [patientData, setPatientData] = useState(blankPatientData);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isResendConfirmationOpen, setIsResendConfirmationOpen] = useState(false);
  const [automaticEmailId, setAutomaticEmailId] = useState(null);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(null)


  const subscriptionsColumns = createSubscriptionsColumns(
    setSelectedRow,
    setIsDetailsOpen,
    setIsUnsubscribeOpen
  );

  const updateSelectedRow = (updatedSubscriptions) => {
    const selectedRowId = selectedRow?.id;
    const updatedRow = updatedSubscriptions.find(
      (sub) => sub.id === selectedRowId,
    );
    setSelectedRow(updatedRow);
  };

  const fetchPatientData = (consultParam) => {
    if (!consultParam) return;

    setIsLoading(true);

    dbGet(`patients/burnable_info/${consultParam}`)
      .then((data) => {
        if (Object.keys(data).length === 0) {
          setIsRutValid({ patient: false, message: "Paciente no registrado" });
          return;
        }

        let myPatientData = {
          id: data.patient.id,
          email: data.patient.email ?? "",
          phone: data.patient.phone ?? "",
          name: `${data.patient.name ?? ""}`,
          lastname: `${data.patient.last_name_1 ?? ""} ${
            data.patient.last_name_2 ?? ""
          }`.trim(),
        };
        setPatientData({ ...patientData, ...myPatientData });

        const mappedSubscriptions = mapSubscriptions(data.subscriptions);

        setSubscriptions(mappedSubscriptions);
        setSalesWithoutCoupon(mapSalesWithoutCoupon(data.sale_without_coupon));
        if (isDetailsOpen) {
          updateSelectedRow(mappedSubscriptions);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        snackbarOpen(
          "error",
          error.error
            ? "Paciente no encontrado"
            : "Error al cargar datos del paciente",
        );
      });
  };

  const updatePatientData = () => {
    var currentRut = patientData.rut;
    var currentRutValidation = verifyRut(currentRut);

    if (currentRutValidation) {
      fetchPatientData(currentRut);
      setIsRutValid({ patient: currentRutValidation, message: "" });
    } else {
      setSubscriptions([]);
      setSalesWithoutCoupon([]);
      setIsRutValid({ patient: currentRutValidation, message: "RUT invalido" });
      setPatientData(blankPatientData);
    }
  };

  const handleRutChange = (e) => {
    const cleanedRut = e.target.value;
    setSearchRut(cleanedRut);
    setPatientData({
      ...patientData,
      rut: cleanedRut,
    });
  };

  useEffect(() => {
    updatePatientData();
  }, [patientData.rut]);

  const handleSaveChanges = async (updatedPatientData) => {
    const data = {
      email: updatedPatientData.email,
      name: updatedPatientData.name,
      last_name: updatedPatientData.lastname,
      phone: updatedPatientData.phone,
    };

    dbPut(`patients/${patientData.rut}`, data)
      .then((data) => {
        snackbarOpen("success", "Paciente editado exitosamente.");
        setPatientData(updatedPatientData);
        fetchPatientData(patientData.rut);
      })
      .catch((error) => {
        if (error.error === "invalid_email") {
          snackbarOpen("error", "Formato de mail inválido");
        } else {
          snackbarOpen(
            "error",
            error[0] ? error[0].description_es : "Error al editar paciente",
          );
        }

        setIsLoading(false);
        return;
      });
  };

  const openResendConfirmation = (automaticEmailId) => {
    setAutomaticEmailId(automaticEmailId);
    setIsResendConfirmationOpen(true);
  };

  const closeResendConfirmation = () => {
    setIsResendConfirmationOpen(false);
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle title="Consulta de pacientes" />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
        xs={12}
      >
        <Grid container spacing={3} xs={12} style={{ marginTop: "20px" }}>
          {!isLoading ? (
            <Grid
              item
              container
              xs={12}
              className={classes.generalPurposeItems}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                backgroundColor: "#f3f3ff",
                border: "1px solid #D1D7E8",
                borderRadius: "20px",
              }}
              spacing={2}
            >
              <Grid item spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.text}>
                    Buscar paciente
                  </Typography>
                  <Divider
                    style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                  />
                  <SimpleRutInput
                    rut={searchRut}
                    onChange={handleRutChange}
                    isRutValid={isRutValid}
                    classes={classes}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" className={classes.text}>
                  Datos del Paciente
                </Typography>
                <Divider
                  style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                />
                <EditablePatientInfo
                  patientData={patientData}
                  classes={classes}
                  onSave={handleSaveChanges}
                />
              </Grid>
              <Grid item xs={12} style={{ minHeight: 200, marginTop: 20 }}>
                <Typography variant="h5" className={classes.text}>
                  Suscripciones
                </Typography>
                <Divider
                  style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                />
                <div className={classes.grid}>
                  <DataGrid
                    autoHeight={true}
                    rows={subscriptions}
                    columns={subscriptionsColumns}
                    disableColumnMenu={true}
                    pageSize={100}
                    checkboxSelection={false}
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    showToolbar={true}
                    localeText={dataGridTexts}
                    hideFooter={true}
                    className={classes.dataGrid}
                    getRowClassName={({ api, id, row }) => {
                      const isEven = api.getRowIndex(id) % 2 === 0;
                      return (
                        "super-app-theme--" +
                        row.type +
                        " " +
                        (isEven ? classes.evenRow : classes.oddRow)
                      );
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ minHeight: 200, marginTop: 20 }}>
                <Typography variant="h5" className={classes.text}>
                  Productos venta sin cupón
                </Typography>
                <Divider
                  style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                />
                <div className={classes.grid}>
                  <DataGrid
                    autoHeight={true}
                    rows={salesWithoutCoupon}
                    columns={salesWithoutCouponColumns}
                    disableColumnMenu={true}
                    pageSize={100}
                    checkboxSelection={false}
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    showToolbar={true}
                    localeText={dataGridTexts}
                    hideFooter={true}
                    className={classes.dataGrid}
                    getRowClassName={({ api, id, row }) => {
                      const isEven = api.getRowIndex(id) % 2 === 0;
                      return (
                        "super-app-theme--" +
                        row.type +
                        " " +
                        (isEven ? classes.evenRow : classes.oddRow)
                      );
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
      
      {/* Dialogs */}
      <SubscriptionDetailsDialog
        row={selectedRow}
        open={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
        snackbarOpen={snackbarOpen}
        patient={patientData}
        openResendConfirmation={openResendConfirmation}
      />
      <UnsubscribePatientDialog
        row={selectedRow}
        open={isUnsubscribeOpen}
        onClose={() => setIsUnsubscribeOpen(false)}
        snackbarOpen={snackbarOpen}
        patient={patientData}
        refreshData={() => fetchPatientData(patientData.rut)}
      />
      
      <ResendConfirmationDialog
        open={isResendConfirmationOpen}
        onClose={closeResendConfirmation}
        snackbarOpen={snackbarOpen}
        automaticEmailId={automaticEmailId}
        email={patientData?.email}
        refreshData={() => fetchPatientData(patientData.rut)}
      />
    </>
  );
}
