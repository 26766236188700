import React, { useState } from "react";
// Components
import { Typography } from "components/Wrappers/Wrappers";
import PopupMessage from "components/PopupMessage/PopupMessage";
import { Button } from "components/Wrappers/Wrappers";
// Functions and helpers
import { dbPost } from "utils/DBFetchers.js";


export default function UnsubscribePatientDialog({
  row,
  open,
  onClose,
  patient,
  snackbarOpen,
  refreshData
}) {
  const [loading, setLoading] = useState(false);

  const unsubscribePatient = async () => {
    setLoading(true);

    try {
      await dbPost(`burnable/unsubscribe/${row.id}`);
      snackbarOpen("success", "Paciente desuscrito exitosamente.");
      await refreshData();
      onClose();
    } catch (error) {
      snackbarOpen(
        "error",
        error[0] ? error[0].description_es : "Error desuscribir paciente.",
      );
    }
    setLoading(false);
  }

  return (
    <PopupMessage
      open={open}
      executeButton={
        <Button
          onClick={() => unsubscribePatient()}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Aceptar
        </Button>
      }
      handleClose={onClose}
      title={`Desuscribir paciente`}
      closeColor={"secondary"}
      maxWidth={false}
    >
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        Producto: {row?.presentation}
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 10, textWrap: "wrap" }}>
        Nombre del paciente: {patient?.name} {patient?.lastname}
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        RUT del paciente: {patient?.rut}
      </Typography>

      <Typography variant="h6" style={{ marginBottom: 10 }}>
        <strong>Esta acción es irreversible. <br />
        El paciente no recibirá más emails para este producto.</strong>
      </Typography>
    </PopupMessage>
  );
};