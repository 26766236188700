import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { createTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import { Redirect, Route, useHistory } from "react-router-dom";
import { getRoleName } from "utils/roleNameGetter";
import logo from "images/logoHeaderRS.png";
import SMlogo from "images/solo-mujer-logo.png";
import { roleTranslation } from "pages/users/roles/utils";

// styles
import useStyles from "./styles";

// components
import { Typography } from "components/Wrappers/Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import { useUserDispatch, signOut } from "context/UserContext";
import routes from "routes";
import searchbarRoles from "utils/searchbarRoles";
import SearchBar from "components/CustomFields/Searchbar";
import StagingTag from "components/StagingTag";

const disabledSwitchRoutes = [
  routes.abmVademecumsEdit,
  routes.abmProductsEdit,
  routes.abmProductsNew,
  routes.abmPharmaciesNew,
  routes.abmPharmaciesEdit,
  routes.abmDistributorsNew,
  routes.abmDistributorsEdit,
  routes.abmDistributors,
  routes.abmPharmacies,

  routes.abmMedics,
  routes.abmMedicsEdit,
  routes.abmMedicsNew,
  routes.abmPharmaGroups,
  routes.usersNewUser,
  routes.usersEditUser,
  routes.abmProducts,
  routes.benefitsConsultDoctor,
  routes.profile,
  routes.delete,
];

const hiddenSwitchRoutes = [
  routes.profile,
  routes.auditControlPanel,
  routes.auditInscriptions,
  routes.benefitsImportBurnable,
  routes.controlVentasRealizadasBurnable,
  routes.auditSales,
  routes.auditDataQuality,
  routes.auditDigitalInscription,
  routes.auditApprovalWorkflow,
  routes.auditPatterns,
  routes.auditImport,
  routes.traceability,
  routes.auditUF,
  routes.usersUsers,
  routes.usersRoles,
  routes.delete,
  routes.abmKam,
  routes.abmKamEdit,
  routes.abmKamNew,
  routes.abmMedics,
  routes.abmMedicsEdit,
  routes.abmMedicsNew,
  routes.abmVisitors,
  routes.abmVisitorsEdit,
  routes.abmVisitorsNew,
  routes.abmKam,
  routes.sendCoupon,
  routes.accreditCoupon,
  routes.abmKamEdit,
  routes.abmKamNew,
  routes.abmSalcobrand,
  routes.abmSalcobrandEdit,
  routes.abmSalcobrandNew,
  routes.reportsConsultPatient,
  routes.createReports,
  routes.reportsDashboard,
  routes.reportsSMDashboard,
  routes.reportsDashboardOld,
  routes.reportsSMDashboardOld,
  routes.auditDashboard,
  routes.traceabilityDashboard,
  routes.benefitsNewSaleBurnable,
  routes.benefitsNewSaleBurnableNoCoupon,
  routes.benefitsNewCupon,
  routes.burnableSalesPharmacies,
  routes.benefitsImportBurnablePharmacies,
  routes.controlVentasRealizadasBurnablePharmacies,
  routes.benefitsNewSale,
  routes.benefitsImport,
  routes.benefitsConsultProduct,
  routes.burnableNewSaleCouponPharmacies,
  routes.burnableNewSaleNoCouponPharmacies,
  routes.controlVentasRealizadasBurnablePharmacies,
  routes.benefitsImportBurnablePharmacies,
  routes.benefitsNewCuponPharmacies,
  routes.generateVisitorQR,
  routes.consultUserData
];

const hiddenSwitchRoles = ["commertial"];

const disabledAuthoritarianRoutes = [
  routes.benefitsNewCupon,
  routes.benefitsNewSale,
  routes.benefitsImport,
];

export default function Header(props) {
  var classes = useStyles();
  const history = useHistory();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);
  var [currentProgramId, setCurrentProgramId] = useState(
    localStorage.getItem("programId"),
  );
  var [isSwitchDisabled, setSwitchDisabled] = useState(
    localStorage.getItem("switchDisabledActivePrograms") !== "0",
  );
  var [isSwitchHidden, setSwitchHidden] = useState(false);
  var [switchDisabledActiveProgram, setSwitchDisabledActiveProgram] = useState(
    localStorage.getItem("switchDisabledActivePrograms"),
  );

  // All of these below are needed to check which programs the pharmacy has authoritarian distributors
  // and need to display a read-only view
  // Permissions for searchbar
  var [searchbarValues, setSearchbarValues] = useState({});

  const updateSearchbarValues = () => {
    let authoritarianProgramRender = false;
    try {
      authoritarianProgramRender =
        getRoleName() === "pharmacies" &&
        JSON.parse(localStorage.getItem("authoritarianPrograms")).includes(
          +localStorage.getItem("programId"),
        );
    } catch {
      authoritarianProgramRender = false;
    }
    setSearchbarValues(
      searchbarRoles[getRoleName()]
        ? authoritarianProgramRender
          ? searchbarRoles.pharmacies_read_only
          : searchbarRoles[getRoleName()]
        : searchbarRoles.unknown,
    );
    if (
      authoritarianProgramRender &&
      disabledAuthoritarianRoutes.includes(history.location.pathname)
    ) {
      history.push(routes.home);
    }
  };

  React.useEffect(() => {
    updateSearchbarValues();
  }, []);
  ////////

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    updateSearchbarValues();
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
      setCurrentProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);

  React.useEffect(() => {
    if (!searchbarValues) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);
  ////////

  /////////////////////////////////////////////////////////////
  const changeProgram = (value) => {
    localStorage.setItem("programId", value);
    setCurrentProgramId(value);
    window.dispatchEvent(new Event("storage"));
  };

  // now deprecated because of the above, TODO remove carefully
  React.useEffect(() => {
    setSwitchHidden(hiddenSwitchRoutes.includes(history.location.pathname) || hiddenSwitchRoles.includes(getRoleName()));
    if (switchDisabledActiveProgram !== "0") return;
    setSwitchDisabled(disabledSwitchRoutes.includes(history.location.pathname));
  }, [history.location.pathname]);

  const redirectTo = (e, value) => {
    if (!(value in searchbarValues)) return;
    history.push(searchbarValues[value]);
  };

  const [changeProgramPopup, setChangeProgramPopup] = React.useState({
    status: false,
    name: "",
    id: "",
  });

  const handleChangeProgramPopupOpen = (id, name) => {
    setChangeProgramPopup({
      ...changeProgramPopup,
      status: true,
      name: name,
      id: id,
    });
  };
  const handleChangeProgramPopupClose = () => {
    setChangeProgramPopup({
      ...changeProgramPopup,
      status: false,
      name: "",
      id: "",
    });
  };
  const handleChangeProgram = () => {
    handleChangeProgramPopupClose();
    changeProgram(changeProgramPopup.id);
  };

  return (
    <>
      <Dialog
        open={changeProgramPopup.status}
        onClose={handleChangeProgramPopupClose}
      >
        <DialogTitle>{`Cambio de programa`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Va a cambiar de programa a {changeProgramPopup.name}.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleChangeProgramPopupClose}
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleChangeProgram}
            color={changeProgramPopup.id === "1" ? "primary" : "secondary"}
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Cambiar a {changeProgramPopup.name}
          </Button>
        </DialogActions>
      </Dialog>

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (
              <ArrowBackIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            )}
          </IconButton>
          {/* TODO: check which one to use*/}
          <Link href={routes.home}>
            {localStorage.getItem("programId") === "1" ? (
              <img
                src={logo}
                alt="Logo Receta Solidaria"
                className={classes.logotypeImage}
              />
            ) : localStorage.getItem("programId") === "2" ? (
              <img
                src={SMlogo}
                alt="Logo Solo Mujer"
                className={classes.logotypeImageSM}
              />
            ) : (
              <></>
            )}
          </Link>
          <StagingTag
            style={{
              marginLeft: "10px",
            }}
          />
          {/*<Typography variant="h6" weight="medium" className={classes.logotype}>
          Receta Solidaria
            </Typography>*/}
          <div className={classes.grow} />
          {isSwitchHidden ? (
            <></>
          ) : (
            <div className={classes.toolbar}>
              {"Selector de Programa:"}
              <Button
                disabled={
                  switchDisabledActiveProgram === "1"
                    ? true
                    : currentProgramId === "1" || isSwitchDisabled
                }
                variant="contained"
                color="primary"
                className={
                  switchDisabledActiveProgram === "1"
                    ? classes.programButtonSpacingDisabledActivePrimary
                    : isSwitchDisabled
                    ? classes.programButtonSpacingDisabledPrimary
                    : classes.programButtonSpacingPrimary
                }
                onClick={() =>
                  handleChangeProgramPopupOpen("1", "Receta Solidaria")
                }
              >
                Receta Solidaria
              </Button>
              <Button
                disabled={
                  switchDisabledActiveProgram === "2"
                    ? true
                    : currentProgramId === "2" || isSwitchDisabled
                }
                variant="contained"
                color="secondary"
                className={
                  switchDisabledActiveProgram === "2"
                    ? classes.programButtonSpacingDisabledActiveSecondary
                    : isSwitchDisabled
                    ? classes.programButtonSpacingDisabledSecondary
                    : classes.programButtonSpacingSecondary
                }
                onClick={() => handleChangeProgramPopupOpen("2", "Solo Mujer")}
              >
                Solo Mujer
              </Button>
            </div>
          )}

          <div>
            <SearchBar
              options={searchbarValues}
              onChange={(e, value) => redirectTo(e, value)}
            />
          </div>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            {/*TODO agregar logica de busqueda del usuario actual al local storage*/}
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                {localStorage.getItem("username")}
              </Typography>
              <Typography className={classes.profileMenuItem} component="a">
                {roleTranslation[getRoleName()]
                  ? roleTranslation[getRoleName()]
                  : getRoleName()}
              </Typography>
            </div>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => {
                history.push(routes.profile);
                setProfileMenu(null);
              }}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Perfil
            </MenuItem>
            <div className={classes.profileMenuUser}>
              <Typography
                className={classes.profileMenuLink}
                color="primary"
                onClick={() => signOut(userDispatch, props.history)}
              >
                Cerrar sesión
              </Typography>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}
